"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const appConfig = {
    theme: {
        dark: false,
    },
    appName: 'Liella! Collection',
    organizationId: 'N3h112IsKwvvPlyZ88Pc',
    version: '1.0',
    ionicAppId: 'b6e951a0',
    applicationKey: 'liella',
    applicationType: 'standalone',
    artistId: 'jqC953f59ThhTrHQN1D7yVyATII2',
    languages: ['ja', 'en'],
    ios: {
        bundleId: 'com.utoniq.liellacollection',
        appId: '6473574981',
    },
    android: {
        bundleId: 'com.utoniq.liellacollection',
    },
    platform: ['web', 'native'],
    custom: {
        modules: {
            tutorial: false,
            trade: true,
            membership: false,
            thread: true,
            prepaidPoint: {
                chargeable: true,
            },
            gacha: true,
            nft: false,
            liveStream: false,
            item: true,
            store: true,
            campaign: false,
            mission: true,
            resale: false,
            preSale: false,
            event: false,
            cart: false,
            pickup: false,
            eprint: false,
            konbiniPayment: true,
        },
        tutorials: [
            'images/tutorial/Tutorial01.jpg',
            'images/tutorial/Tutorial02.jpg',
            'images/tutorial/Tutorial03.jpg',
            'images/tutorial/Tutorial04.jpg',
        ],
        globalTabs: [],
        artistTabs: ['home', 'collections', 'gachas', 'trade', 'myProfile'],
    },
    deeplinkUrl: 'https://liellacollection.utoniq.com',
    helpUrl: 'https://help.utoniq.com/ja/collections/7203956',
    storagePath: 'liellacollection',
    dynamicLinkPrefix: 'liellacollection',
    deeplinkProd: 'liellacollection.utoniq.com',
};
exports.default = appConfig;
